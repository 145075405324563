import {IDeacomItemService} from "./IDeacomItemService";
import {handleResponse} from "../../HelperFunctions/handleResponse";

export default class DeacomItemService implements IDeacomItemService {
    baseUrl: string = '/api/deacomItems/';

     GetItems = async (signal:AbortSignal, search: string) => {
        return await this.GetRequest(`list?search=${search}`, signal);
    }
     UploadCsvFile = async (file: File, signal: AbortSignal) => {
        return await this.PostFileRequest(`uploadFile`, file, signal)
    }

    private async GetRequest(path: string, signal: AbortSignal) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
        })
    }
    
    private async PostFileRequest(path: string, file: File, signal: AbortSignal){
        const url = this.baseUrl + path

        const formData = new FormData();
        formData.append('file', file);

        const requestOptions = {
            method: "POST",
            body: formData,
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
        })
    }
}