import {ILookupService} from "../Lookup/ILookupService";
import {IAPIService} from "../APIService/IAPIService";
import {OptionType} from "../../ViewModels/OptionType";

export class PricingLevelService implements ILookupService {
    baseUrl: string = '/api/pricingLevels/'
    private readonly _apiService: IAPIService;

    constructor(apiService: IAPIService) {
        this._apiService = apiService;
    }

    ListOptions(): Promise<OptionType[]> {
        return this._apiService.Get(this.baseUrl + "list");
    }
}