import {Dispatch, SetStateAction} from "react";
import {Combobox} from "@headlessui/react";
import {OmniSearchInputComponent} from "./OmniSearchInputComponent";
import {OmniSearchOptionsComponent} from "./OmniSearchOptionsComponent";
import {OmniSearchCategoryOptions} from "./Hooks/useOmniSearch";

interface IOmniSearchTypeaheadProps {
    listInnerRef: any,
    options: OmniSearchCategoryOptions[],
    selectedCategory: any,
    setSelectedCategory: any,
    isLoaded: boolean,
    icon?: any,
    disabled: boolean,
    dataTestId: string,
    placeholderText: any,
    query: string,
    setQuery: Dispatch<SetStateAction<any>>,
    onNavigationClick: (id: number, category: string) => void,
}

export const OmniSearchTypeaheadComponent = ({...props}: IOmniSearchTypeaheadProps) => {
    const {
        listInnerRef,
        selectedCategory,
        setSelectedCategory,
        options,
        isLoaded,
        icon,
        disabled,
        dataTestId,
        placeholderText,
        setQuery,
        query,
        onNavigationClick,
    } = props;

    return (
        <Combobox as="div" value={selectedCategory}
                  onChange={setSelectedCategory} disabled={disabled}
                  aria-labelledby="combobox" data-testid={dataTestId}>
            <div className="relative">
                <OmniSearchInputComponent setQuery={setQuery} icon={icon} placeholderText={placeholderText}/>
                <OmniSearchOptionsComponent isLoaded={isLoaded} onNavigationClick={onNavigationClick} query={query}
                                            options={options} listInnerRef={listInnerRef}/>
            </div>
        </Combobox>
    )
}