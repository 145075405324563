import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {OmniSearchTypeaheadComponent} from "./OmniSearchTypeaheadComponent";
import {useOmniSearch} from "./Hooks/useOmniSearch";

export const OmniSearchComponent = () => {
    const {
        options,
        listInnerRef,
        selectedCategory,
        isLoaded,
        query,
        setQuery,
        setSelectedCategory,
        handleOmniSearchValue,
        handleNavigationToSelectedValue
    } = useOmniSearch();

    return (
        <>
            <OmniSearchTypeaheadComponent
                listInnerRef={listInnerRef}
                options={options}
                icon={<MagnifyingGlassIcon className={"w-5 h-5 text-gray-500"}/>}
                selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}
                isLoaded={isLoaded}
                disabled={false} dataTestId={"omnisearch"}
                placeholderText={'Search for Customer or Plan'}
                query={query} 
                setQuery={setQuery}
                onNavigationClick={handleNavigationToSelectedValue}
            />
        </>
    )
}