import {handleResponse} from "../../HelperFunctions/handleResponse";
import {IUserService} from "./IUserService";
import {UserEditViewModel} from "../../ViewModels/Users/UserEditViewModel";
import {OptionType} from "../../ViewModels/OptionType";

export default class UserService implements IUserService {
    baseUrl: string = '/api/users/';

    GetUsersList = async (page: number, search: string, signal: AbortSignal) => {
        return await this.GetRequest(`list?page=${page}&search=${search}`, signal);
    };
    
    GetUser = async (id: number, signal:AbortSignal) => {
        return await this.GetRequest(`${id}`, signal)
    }
    UpdateUser = async (id: number, data: UserEditViewModel, signal: AbortSignal) => {
        return await this.PutRequest(id, data, signal);
    }
    
    AddUser = async(vm: UserEditViewModel) => {
        return await this.PostRequest("add", vm);
    };
    
    RemoveUser = async (id: number, signal: AbortSignal) => {
        return await this.DeleteRequest(id, signal);
    };
    
    GetUserInfo = async (signal: AbortSignal) => {
        return await this.GetRequest("getUserInformation", signal);
    }
    
    GetUserBuilder = async (userId: number) => {
        return await this.GetRequest(`${userId}/customer`);
    }
    
    ListOptions = async (): Promise<OptionType[]> => {
        let data = await this.GetRequest("list");
        return data.map((a: { name: string, firstName: string, lastName: string }) => {
            a.name = `${a.firstName} ${a.lastName}`
            return a
        })
        
    }
    
    private async PostRequest(path: string, requestObject: any) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: requestObject ? JSON.stringify(requestObject) : ""
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
    }

    private PutRequest = async (id: number, requestObject: any, signal: AbortSignal) => {
        let url = this.baseUrl + id;
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            signal: signal,
            body: requestObject ? JSON.stringify(requestObject) : ""
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
    }

    private async GetRequest(path: string, signal?: AbortSignal) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }

    private async DeleteRequest(id: number, signal: AbortSignal) {
        let url = this.baseUrl + id;
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            signal: signal
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
    }
}