import {IMagicLinkService} from "./IMagicLinkService";
import {handleResponse} from "../../HelperFunctions/handleResponse";
import {MagicLinkCreateViewModel} from "../../ViewModels/MagicLink/MagicLinkCreateViewModel";

export default class MagicLinkService implements IMagicLinkService {
    private baseUrl: string = '/api/magicLinks/';
    private loginRoute: string = 'login';
    constructor(isExternalUser?: boolean) {
        if(isExternalUser) {
            this.baseUrl = '/api/magicLinks/';
            this.loginRoute = 'vpo/login'
        }
    }
    

    GetMagicLink = async (hash: string, signal: AbortSignal) => {
        return await this.GetRequest(`decode/${hash}`, signal);        
    };
    
    CreateMagicLink = async (magicLinkCreateViewModel: MagicLinkCreateViewModel) => {
        return await this.PostRequest(this.loginRoute, magicLinkCreateViewModel);
    };
    
    private async PostRequest(path: string, requestObject: any) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: requestObject ? JSON.stringify(requestObject) : ""
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }
    
    private async GetRequest(path: string, signal: AbortSignal) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }
} 