import React, {createContext} from "react";
import {Services} from "./IServices";
import {SystemSettingsService} from "../Services/SystemSettings/SystemSettingsService";
import CustomerService from "../Services/Customers/CustomerService";
import VPOService from "../Services/VPO/VPOService";
import OmniSearchService from "../Services/OmniSearch/OmniSearchService";
import UserService from "../Services/Users/UserService";
import MagicLinkService from "../Services/MagicLink/MagicLinkService";
import DeacomItemService from "../Services/VPO/DeacomItemService";
import {RegionService} from "../Services/Region/RegionService";
import {PricingLevelService} from "../Services/PricingLevel/PricingLevelService";
import {PlanService} from "../Services/Plans/PlanService";
import {APIService} from "../Services/APIService/APIService";
import RequirementSetService from "../Services/RequirementSet/RequirementSetService";
import DisciplineService from "../Services/Disciplines/DisciplineService";
import {PlanVersionService} from "../Services/PlanVersions/PlanVersionService";

const apiService = new APIService();

const ServiceContext = createContext<Services>({
    APIService: new APIService(),
    SystemSettingsService: new SystemSettingsService(),
    OmniSearchService: new OmniSearchService(),
    CustomerService: new CustomerService(apiService),
    VPOService: new VPOService(),
    UserService: new UserService(),
    MagicLinkService: new MagicLinkService(),
    DeacomItemService: new DeacomItemService(),
    RegionService: new RegionService(apiService),
    PricingLevelService: new PricingLevelService(apiService),
    PlanService: new PlanService(apiService),
    RequirementSetService: new RequirementSetService(apiService),
    DisciplineService: new DisciplineService(apiService),
    PlanVersionService: new PlanVersionService(apiService)
});

export const ServiceContextProvider = ({children}: any) => {

    const services = {
        APIService: new APIService(),
        SystemSettingsService: new SystemSettingsService(),
        OmniSearchService: new OmniSearchService(),
        CustomerService: new CustomerService(apiService),
        VPOService: new VPOService(),
        UserService: new UserService(),
        MagicLinkService: new MagicLinkService(),
        DeacomItemService: new DeacomItemService(),
        RegionService: new RegionService(apiService),
        PricingLevelService: new PricingLevelService(apiService),
        PlanService: new PlanService(apiService),
        RequirementSetService: new RequirementSetService(apiService),
        DisciplineService: new DisciplineService(apiService),
        PlanVersionService: new PlanVersionService(apiService)
    } as Services;

    return (
        <ServiceContext.Provider value={services}>
            {children}
        </ServiceContext.Provider>
    )
}

export {ServiceContext}