import React, {PropsWithChildren, useState} from "react";
import {Theme} from "../Themes/types";
import {IUseThemeOptions, themes} from "../Themes/useTheme";
import {applyTheme} from "../Themes/utils";

type ThemeContext = {
    theme: Theme | string;
    toggleTheme: (theme: Theme) => void;
    primaryLogo: string;
    secondaryLogo: string;
    verticalNav: string;
    applyTheme: (theme: string) => void;
    themeName: string;
}

//This creates our user Context which is populated from the callback of getUserInfo.
export const ThemeContext = React.createContext<ThemeContext>({
    theme: "",
    toggleTheme: () => {},
    primaryLogo: "",
    secondaryLogo: "",
    verticalNav: "",
    applyTheme: applyTheme,
    themeName: ""
});

export const ThemeContextProvider = ({children}: PropsWithChildren) => {
    const [selectedTheme, setSelectedTheme] = useState<Theme>(Theme.BeLit);
    const [primaryLogo, setPrimaryLogo] = useState<IUseThemeOptions["primaryLogo"]>(themes[selectedTheme].logos.primary);
    const [secondaryLogo, setSecondaryLogo] = useState<IUseThemeOptions["secondaryLogo"]>(themes[selectedTheme].logos.secondary);
    const [verticalLogo, setVerticalLogo] = useState<IUseThemeOptions["verticalNav"]>(themes[selectedTheme].logos.verticalNav)
    const [themeName, setThemeName] = useState<IUseThemeOptions["themeName"]>(themes[selectedTheme].name);

    const toggleTheme = (theme: Theme) => {
        if(theme && theme != selectedTheme) {
            setSelectedTheme(theme)
            const selectedTheme = themes[theme]
            const {primary, secondary, verticalNav} = selectedTheme.logos
            setPrimaryLogo(primary)
            setSecondaryLogo(secondary)
            setVerticalLogo(verticalNav)
            setThemeName(selectedTheme.name)
        }
        applyTheme(theme)


    }

    //The populated state object that we send to the component that needs Theme information.
    const contextValue = {
        theme: selectedTheme,
        toggleTheme,
        primaryLogo,
        secondaryLogo,
        verticalNav: verticalLogo,
        themeName,
        applyTheme
        
    }

    //What we wrap around our routes
    const {Provider} = ThemeContext;

    return (
        <Provider value={contextValue}>
            {children}
        </Provider>
    )
}