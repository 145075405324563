import React, {lazy, Suspense, useContext} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {useLoadingIndicator} from "./GlobalHooks/useLoadingIndicator";
import {Loading} from "./GlobalComponents/Loading";
import {PrivateRoutes} from "./Routes/PrivateRoutes";
import {UserContext} from "./Context/UserContext";
import {CustomerFormMode} from "./Enums/CustomerFormModeEnum";
import {Theme} from "./Themes/types";
import {PlanFormMode} from "./Enums/PlanFormMode";

//Lazy Load Pages
const VerifyEmail = lazy(() => import('./Pages/VerifyEmail/VerifyEmail').then(module => ({default: module.VerifyEmail})));
const LoginMagicLinkView = lazy(() => import('./Pages/MagicLink/LoginMagicLink/LoginMagicLinkView').then(module => ({default: module.LoginMagicLinkView})));
const ConfirmationPurchaseOrderView = lazy(() => import('./Pages/VPO/ConfirmationPurchaseOrder/ConfirmationPurchaseOrderView').then(module => ({default: module.ConfirmationPurchaseOrderView})));
const VPOFormView = lazy(() => import('./Pages/VPO/VPOFormView').then(module => ({default: module.VPOFormView})))
const Login = lazy(() => import('./Pages/Login/Login').then(module => ({default: module.Login})));
const FinalizePurchaseOrderView = lazy(() => import("./Pages/VPO/FinalizePurchaseOrder/FinalizePurchaseOrderView").then(module => ({default: module.FinalizePurchaseOrderView})));
const InternalVPOFormView = lazy(() => import('./Pages/VPO/Internal/InternalVPOFormView').then(module => ({default: module.InternalVPOFormView})));
const CustomerView = lazy(() => import("./Pages/Customers/CustomerView").then(module => ({default: module.CustomerView})));
const CustomerFormView = lazy(() => import('./Pages/Customers/CustomerFormView').then(module => ({default: module.CustomerFormView})));
const PlanView = lazy(() => import('./Pages/Plans/PlanView').then(module => ({default: module.PlanView})));
const PlanFormView = lazy(() => import('./Pages/Plans/PlanFormView').then(module => ({default: module.PlanFormView})));
const UserAdd = lazy(() => import('./Pages/Users/UserAdd/UserAddView').then(module => ({default: module.UserAdd})));
const UserListView = lazy(() => import("./Pages/Users/UserList/UserListView").then(module => ({default: module.UserListView})));
const UserEditView = lazy(() => import("./Pages/Users/UserEdit/UserEditView").then(module => ({default: module.UserEditView})));
const Settings = lazy(() => import("./Pages/Settings/Settings").then(module => ({default: module.Settings})));

function App() {

    const {isLoading, loadingStyleForWholePage} = useLoadingIndicator();
    const {user} = useContext(UserContext);
    const {userType} = user;
    let Internal = userType === 0;
    let External = userType === 1;
    let Admin = userType === 2;

    return (
        <>
            <Suspense fallback={
                <div className={loadingStyleForWholePage}>
                    <Loading/>
                </div>}
            >
                <Routes>
                    <Route index path="/login" element={<Login theme={Theme.BeLit}/>}/>
                    <Route index path="/vpo/login" element={<Login theme={Theme.TrueHouse}/>}/>
                    <Route index path="/verifyEmail" element={<VerifyEmail theme={Theme.BeLit}/>}/>
                    <Route index path="/vpo/verifyEmail" element={<VerifyEmail theme={Theme.TrueHouse}/>}/>
                    <Route index path='/vpo/magicLinks/decode/:id' element={<LoginMagicLinkView theme={Theme.TrueHouse}/>}/>
                    <Route index path='/magicLinks/decode/:id' element={<LoginMagicLinkView theme={Theme.BeLit}/>}/>
                    <Route path="/" element={<Navigate to={"/login"}/>}/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/customers/:id" element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <CustomerView/> : <Navigate to="/login"/>)}/>
                        <Route path={"/customers/:customerId/plans/:planId"} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <PlanView/> : <Navigate to={"/login"}/>)}/>
                        <Route path={"/customers/:customerId/plans/:planId/options"} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <PlanView/> : <Navigate to={"/login"}/>)}/>
                        <Route path={"/customers/:customerId/plans/:planId/versions"} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <PlanView/> : <Navigate to={"/login"}/>)}/>
                        <Route path={"/customers/:customerId/plans/:planId/revisions"} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <PlanView/> : <Navigate to={"/login"}/>)}/>
                        <Route path="/customers/form/add" element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <CustomerFormView mode={CustomerFormMode.Add}/> : <Navigate to="/login"/>)}/>
                        <Route path="/customers/form/:id/" element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <CustomerFormView mode={CustomerFormMode.Update}/> : <Navigate to="/login"/>)}/>
                        <Route path="/customers/:customerId/plans/form/add" element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <PlanFormView mode={PlanFormMode.Add}/> : <Navigate to="/login"/>)}/>
                        <Route path="/customers/:customerId/plans/form/:planId" element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Admin || Internal ? <PlanFormView mode={PlanFormMode.Update}/> : <Navigate to="/login"/>)}/>
                        <Route path="/vpo" element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                : (External || Internal ? <VPOFormView/> : <Navigate to="/login"/>)}/>
                        <Route path="/vpo/:id/edit" element={
                            isLoading ?
                                <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Internal ?
                                    <InternalVPOFormView/> : <Navigate to="/login"/>)}/>\
                        <Route path="/vpo/:id/confirmation" element={
                            isLoading ?
                                <div className={loadingStyleForWholePage}><Loading/></div>
                                : (External ?
                                    <ConfirmationPurchaseOrderView/> : <Navigate to="/login"/>)}/>
                        <Route path="/vpo/:id/internalConfirmation" element={
                            isLoading ?
                                <div className={loadingStyleForWholePage}><Loading/></div>
                                : (Internal ?
                                    <ConfirmationPurchaseOrderView/> : <Navigate to="/login"/>)}/>
                        <Route path="/vpo/:id/finalize" element={
                            isLoading ?
                                <div className={loadingStyleForWholePage}><Loading/></div>
                                : (External ?
                                    <FinalizePurchaseOrderView/> : <Navigate to="/login"/>)}/>
                        <Route path="/vpo/verify"
                               element={
                                   isLoading ? <div className={loadingStyleForWholePage}><Loading/></div>
                                       : (External || Internal ? <ConfirmationPurchaseOrderView/> :
                                           <Navigate to="/login"/>)}/>
                        <Route path="/users"
                               element={Admin ? <UserListView/> : <Navigate to="/login"/>}/>
                        <Route path="/users/:id"
                               element={Admin ? <UserEditView/> : <Navigate to="/login"/>}/>
                        <Route path="/users/add"
                               element={Admin ? <UserAdd/> : <Navigate to="/login"/>}/>
                        <Route path="/settings"
                               element={Admin ? <Settings/> : <Navigate to="/login"/>}/>
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default App;