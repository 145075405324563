export enum Theme {
    BeLit = "BeLit",
    TrueHouse = "TrueHouse"
}
interface IThemeColors {
    [key: string]: string
}

export interface IThemeLogo {
    primary: string;
    secondary: string;
    verticalNav: string;
    favicon: string;
}

export interface ITheme {
    name: string;
    colors: IThemeColors;
    logos: IThemeLogo;

}

export interface IThemes {
    [key: string]: ITheme;
}

export interface IMappedTheme {
    [key: string]: string | null;
}
