import primaryLogo from "../Assets/BeLitFlameLogoPrimary.png"
import secondaryLogo from "../Assets/BeLitFlameLogoSecondary.png"
import flameLogoRedBg from "../Assets/BeLitFlameLogoPrimary.png.png"
import {ITheme} from "./types";
export const BeLit:ITheme = {
    name: "BeLit",
    colors: {
        offWhite: 'rgba(244, 244, 244, 1)',
        primaryGray: '#58595B',
        secondaryGray: '#9CA3AF',
        bgSecondary: '#9CA3AF',
        disabled: '#E5E7EB',
        darkPrimary: '#6F040D',
        primary: '#9B1521',
        primaryHover: '#B91C1C',
        primaryHalf: 'rgba(155,21,33,0.5)',
        secondary: 'rgba(111, 4, 13, 1)',
        "default-200": "#C6C6C6",
        "default-500": "#58595B",
        black: '#323232',
    },
    logos: {
        primary: primaryLogo,
        secondary: secondaryLogo,
        verticalNav: primaryLogo,
        favicon: primaryLogo
    }
    
}
