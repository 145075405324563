import {IAPIService} from "../APIService/IAPIService";
import {IPlanVersionService} from "./IPlanVersionService";

export class PlanVersionService implements IPlanVersionService {
    baseUrl: string = '/api/planVersions/';
    private readonly _apiService: IAPIService;

    constructor(apiService: IAPIService) {
        this._apiService = apiService;
    }

    GetPlanVersionsByPlanId = async (planId: number) => {
        return await this._apiService.Get(`${this.baseUrl}${planId}`)
    }
}