import {ICustomerService} from "./ICustomerService";
import {IAPIService} from "../APIService/IAPIService";
import {CustomerDtoMapper} from "../../DomainComponents/Customer/CustomerDto";
import {CustomerCreateEditViewModel} from "../../ViewModels/Customers/CustomerCreateEditViewModel";

export default class CustomerService implements ICustomerService {
    baseUrl: string = '/api/customers/';
    private readonly _apiService: IAPIService;

    constructor(apiService: IAPIService) {
        this._apiService = apiService;
    }

    GetCustomerLookupList = async (signal: AbortSignal, search?: string) => {
        return await this._apiService.Get(this.baseUrl + `customerLookup?search=${search}`, signal);
    }

    GetCustomerList = async (signal: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `list`, signal);
    }

    GetSubdivisionsByBuilder = async (customerId: number, search: string, signal: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `${customerId}/shippingLocation?search=${search}`, signal)
    }

    GetSubdivisions = async (signal: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `list`, signal);
    }

    GetCustomerDetail = async (id: number, signal?: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `${id}/view`, signal)
    }
   
    GetCustomerFormDetails = async (signal: AbortSignal, id: number) => {
        const response =  await this._apiService.Get(this.baseUrl + `${id}/details`, signal);
        return CustomerDtoMapper.convertToCustomerCreateViewModel(response);
    }
   
    GetCustomerLookup = async (id: number, signal?: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `${id}`, signal)
    }

    AddCustomer = async (customerCreateVM: CustomerCreateEditViewModel, signal: AbortSignal) => {
        return await this._apiService.Post(this.baseUrl, CustomerDtoMapper.convertToCustomerDTO(customerCreateVM), signal);
    }
    
    ListCustomerPlans = async (customerId: number, signal: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `${customerId}/listPlans`, signal)
    }

    UpdateCustomer = async (customerId: number, customerCreateEditViewModel: CustomerCreateEditViewModel, signal: AbortSignal) => {
        return await this._apiService.Put(this.baseUrl + `${customerId}`, CustomerDtoMapper.convertToCustomerDTO(customerCreateEditViewModel), signal);
    }
}