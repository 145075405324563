import {Combobox} from "@headlessui/react";
import {OmniSearchOptionRowComponent} from "./OmniSearchOptionRowComponent";
import {OmniSearchCategoryOptions} from "./Hooks/useOmniSearch";
import {OptionType} from "../../ViewModels/OptionType";

interface IOmniSearchOptionsComponentProps {
    listInnerRef: any,
    query: string,
    isLoaded: boolean,
    onNavigationClick: (id: number, category: string, categoryId?: number) => void,
    options: OmniSearchCategoryOptions[],
}

export const OmniSearchOptionsComponent = ({...props}: IOmniSearchOptionsComponentProps) => {
    const {query, isLoaded, options, listInnerRef, onNavigationClick} = props;
    //Gets the first item in the list of options
    const listOfCustomers = options[0];
    //Gets the last item in the options category array
    const listOfPlans = options[options.length - 1];
    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <>
            {query !== "" && (
                <Combobox.Options ref={listInnerRef} aria-label={"combobox options"}
                                  className="no-scrollbar absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary focus:outline-none sm:text-sm">
                    {query !== "" && options.map((categoryOption, index: number) => {
                        return (
                            <div key={index}>
                                {categoryOption.options.length !== 0 && (
                                    <>
                                        {listOfPlans && categoryOption.category !== "Customers" && listOfCustomers.options.length !== 0 && <hr className={"border-primary"}/>}
                                        <span className={"flex justify-start mx-5 font-sans text-base font-medium text-primary py-1"}>{categoryOption.category}</span>
                                        {isLoaded && categoryOption.options.map((option: OptionType, index: number) => {
                                            return (
                                                <div key={index}>
                                                    <Combobox.Button className={"w-full hover:bg-gray-100 hover:cursor-pointer"}
                                                                     onClick={() => onNavigationClick(option.id, categoryOption.category, option?.customerId)}
                                                                     key={index}>
                                                        <OmniSearchOptionRowComponent key={index} index={index}
                                                                                      option={option}
                                                                                      classNames={classNames}/>
                                                    </Combobox.Button>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        )
                    })}
                </Combobox.Options>
            )}
        </>
    )
}