import {IAPIService} from "../APIService/IAPIService";
import {IDisciplineService} from "./IDisciplineService";
import {OptionType} from "../../ViewModels/OptionType";
export default class DisciplineService implements IDisciplineService {
    baseUrl: string = '/api/disciplines';
    private readonly _apiService: IAPIService;

    constructor(apiService: IAPIService) {
        this._apiService = apiService;
    }

    GetDisciplineList = async (signal: AbortSignal) => {
        return await this._apiService.Get(`${this.baseUrl}/list`, signal);
    }
    ListOptions = async (signal: AbortSignal): Promise<OptionType[]> => {
        return await this._apiService.Get(`${this.baseUrl}/list`, signal);
    }

}