export const Loading = () => {
    
    return (
        <>
            <div className="loadingIndicator" data-testid={"loading"}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    )
} 