import {useEffect, useState} from "react";

export const useLoadingIndicator = () => {
    //State
    const [isLoading, setIsLoading] = useState(true);

    //Pre-defined timer for showing loading indicator
    const loadingStyleForWholePage = "min-h-screen min-w-screen flex flex-col justify-center items-center";
    
    //Functions
    const interval = () => {
        const interval = setInterval(() => {
            setIsLoading(false);
        }, 500);
        return () => clearInterval(interval);
    }

    useEffect(() => {
        interval();
    }, [])
    
    return {isLoading, loadingStyleForWholePage};
}