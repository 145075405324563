import {IPlanService} from "Services/Plans/IPlanService";
import {IAPIService} from "Services/APIService/IAPIService";
import {PlanEditViewModel} from "ViewModels/Plans/PlanEditViewModel";
import {PlanOptionEditViewModel} from "ViewModels/Plans/PlanOptionEditViewModel";
import {ElevationOptionEditViewModel} from "../../ViewModels/Plans/ElevationOptionEditViewModel";

export class PlanService implements IPlanService {
    baseUrl: string = '/api/plans/';
    private readonly _apiService: IAPIService;

    constructor(apiService: IAPIService) {
        this._apiService = apiService;
    }

    GetPlanLookupDetails = async (planId: number, signal?: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `${planId}`, signal)
    }
    
    AddPlan = async (planCreateVm: PlanEditViewModel, signal: AbortSignal) => {
        planCreateVm.requirementSetCreateViewModel.id = 0;
        return await this._apiService.Post(this.baseUrl, planCreateVm, signal);
    }

    UpdateCustomer = async (planId: number, customerEditVM: PlanEditViewModel, signal: AbortSignal) => {
        return await this._apiService.Put(this.baseUrl + `${planId}`, customerEditVM, signal);
    }

    GetPlanDetails = async (planId: number, signal: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `${planId}/view`, signal);
    }
    
    GetPlanOptionDetails = async (planId: number, signal: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `${planId}/planOptions`, signal);
    }
    
    GetPlanFormDetails = async (planId: number, signal: AbortSignal) => {
        return await this._apiService.Get(this.baseUrl + `${planId}/details`, signal);
    }
    
    AddPlanOption = async (planId: number, planOptionEditVM: PlanOptionEditViewModel, signal: AbortSignal) => {
        return await this._apiService.Post(this.baseUrl + `${planId}/planOptions/add`, planOptionEditVM, signal);
    }
    
    AddPlanElevation = async (planId: number, elevationCreateVm: ElevationOptionEditViewModel, signal: AbortSignal) => {
        return await this._apiService.Post(this.baseUrl + `${planId}/elevations/add`, elevationCreateVm, signal);
    }

    UpdatePlanElevation = async (planId: number, planElevationEditVM: ElevationOptionEditViewModel, signal: AbortSignal) => {
        return await this._apiService.Put(`${this.baseUrl}${planId}/elevations/update`, planElevationEditVM, signal)
    }
    
    UpdatePlan = async (planId: number, planEditVM: PlanEditViewModel, signal: AbortSignal) => {
        return await this._apiService.Put(`${this.baseUrl}${planId}`, planEditVM, signal)
    }
    
    UpdatePlanOption = async (planId: number, planOptionEditVm: PlanOptionEditViewModel, signal: AbortSignal) => {
        return await this._apiService.Put(`${this.baseUrl}${planId}/planOptions/update`, planOptionEditVm, signal)
    }
    GetPlanOptionsByPlan = async (planId: number) => {
        return await this._apiService.Get(`${this.baseUrl}${planId}/planOptions`)
    }
}