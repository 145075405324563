import {CustomerCreateEditViewModel} from "../../ViewModels/Customers/CustomerCreateEditViewModel";
import {ContactViewModel} from "../../ViewModels/Contact/ContactViewModel";
import {AddressViewModel} from "../../ViewModels/Address/AddressViewModel";
import {RequirementSetViewModel} from "../../ViewModels/RequirementSet/RequirementSetViewModel";

export type CustomerCreateEditDto = {
     name: string;
     marketTitle: string;
     pricingLevelId: number;
     pricingLevelName: string;
     regionId?: number;
     regionName: string;
     parentCustomerId?: number;
     parentCustomerName?: string;
     primaryContact: ContactViewModel;
     billingContact: ContactViewModel;
     shippingAddress: AddressViewModel;
     billingAddress: AddressViewModel;
     externalCustomerId?: number;
     requirementSetCreateViewModel: RequirementSetViewModel;
}

export class CustomerDtoMapper {
    
    static convertToCustomerDTO(customerViewModel: CustomerCreateEditViewModel) {
        return {
            name: customerViewModel.customerInfo.name,
            marketTitle: customerViewModel.customerInfo.marketTitle,
            pricingLevelId: customerViewModel.customerInfo.pricingLevels.id,
            pricingLevelName: customerViewModel.customerInfo.pricingLevels.name,
            externalCustomerId: -1,
            billingAddress: customerViewModel.billingAddress,
            billingContact: customerViewModel.billingContact,
            primaryContact: customerViewModel.primaryContact,
            regionId: customerViewModel.customerInfo.region.id,
            regionName: customerViewModel.customerInfo.region.name,
            shippingAddress: customerViewModel.shippingAddress,
            requirementSetCreateViewModel: customerViewModel.requirementSetCreateViewModel,
        } as CustomerCreateEditDto
    }
    
    static convertToCustomerCreateViewModel(customerDto: CustomerCreateEditDto) {
        return {
            customerInfo: {
                name: customerDto.name,
                marketTitle: customerDto.marketTitle,
                region: {
                    id: customerDto.regionId,
                    name: customerDto.regionName
                },
                pricingLevels: {
                    id: customerDto.pricingLevelId,
                    name: customerDto.pricingLevelName
                },
            },
            primaryContact: {
                id: customerDto.primaryContact.id,
                firstName: customerDto.primaryContact.firstName,
                lastName: customerDto.primaryContact.lastName,
                phone: customerDto.primaryContact.phone,
                contactRole: customerDto.primaryContact.contactRole,
                email: customerDto.primaryContact.email
            },
            billingAddress: {
                id: customerDto.billingAddress.id,
                address1: customerDto.billingAddress.address1,
                address2: customerDto.billingAddress.address2,
                city: customerDto.billingAddress.city,
                state: customerDto.billingAddress.state,
                zipCode: customerDto.billingAddress.zipCode,
                phone: customerDto.billingAddress.phone,
                country: customerDto.billingAddress.country
            },
            billingContact: {
                id: customerDto.billingContact.id,
                firstName: customerDto.billingContact.firstName,
                lastName: customerDto.billingContact.lastName,
                phone: customerDto.billingContact.phone,
                contactRole: customerDto.billingContact.contactRole,
                email: customerDto.billingContact.email
            },
            shippingAddress: {
                id: customerDto.shippingAddress.id,
                address1: customerDto.shippingAddress.address1,
                address2: customerDto.shippingAddress.address2,
                city: customerDto.shippingAddress.city,
                state: customerDto.shippingAddress.state,
                zipCode: customerDto.shippingAddress.zipCode,
                phone: customerDto.shippingAddress.phone,
                country: customerDto.shippingAddress.country
            },
            requirementSetCreateViewModel: {
                id: customerDto.requirementSetCreateViewModel.id,
                disciplineSetEditViewModels: customerDto.requirementSetCreateViewModel.disciplineSetEditViewModels
                
            }
        } as CustomerCreateEditViewModel
    }
    
}