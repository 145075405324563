import {IRequirementSetService} from "./IRequirementSetService";
import {IAPIService} from "../APIService/IAPIService";
import {RequirementSetViewModel} from "../../ViewModels/RequirementSet/RequirementSetViewModel";
export default class RequirementSetService implements IRequirementSetService {
    baseUrl: string = '/api';
    private readonly _apiService: IAPIService;

    constructor(apiService: IAPIService) {
        this._apiService = apiService;
    }
    
    GetDisciplineList = async () => {
        return await this._apiService.Get(`${this.baseUrl}/disciplines/list`);
    }
    
    GetSkillSets = async () => {
        return await this._apiService.Get(`${this.baseUrl}/skillSets/list`);
    }

    UpdateRequirementSet = async (id:number, data: RequirementSetViewModel, signal: AbortSignal) => {
        return await this._apiService.Put(`${this.baseUrl}/requirementSets/${id}`, data, signal);
    }
    
    GetByCustomerId = async (customerId: number, signal: AbortSignal) => {
        return await this._apiService.Get(`${this.baseUrl}/requirementSets/${customerId}`, signal);
    }
}