import {Combobox} from "@headlessui/react";
import {OptionType} from "../../ViewModels/OptionType";

interface IOmniSearchRowProps {
    index: number,
    option: OptionType,
    classNames: (string: string) => string;
}

export const OmniSearchOptionRowComponent = ({...props}: IOmniSearchRowProps) => {
    const {index, option, classNames} = props;
    return(
        <Combobox.Option
            aria-label={`option ${option.id}`}
            key={index}
            value={option}
            className={'relative select-none py-2 flex justify-start ml-5'}
        >
            <span className={classNames('block truncate text-gray-800 font-normal')}>{option.name}</span>
        </Combobox.Option>
    )
}