import {handleResponse} from "../../HelperFunctions/handleResponse";
import {IOmniSearchService} from "./IOmniSearchService";

export default class OmniSearchService implements IOmniSearchService {
    baseUrl: string = '/api/omnisearch/';

    Search = async (signal: AbortSignal, search: string) => {
        return await this.GetRequest(`search?search=${search}`, signal);
    }
    
    private async GetRequest(path: string, signal: AbortSignal) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }
}