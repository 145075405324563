import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import {UserContextProvider} from "./Context/UserContext";
import {ServiceContextProvider} from "./Context/ServiceContext";
import {ThemeContextProvider} from "./Context/ThemeContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <ErrorBoundary>
            <ServiceContextProvider>
                <ThemeContextProvider>
                    <UserContextProvider>
                        <App/>
                    </UserContextProvider>
                </ThemeContextProvider>
            </ServiceContextProvider>
        </ErrorBoundary>
    </BrowserRouter>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();