export async function handleResponse(response: any) {
    if (!response.ok) {
        return await response.json().then((x: any) => {
            return Promise.reject(x);
        });
    }
    if(response.status === 202){
        return;
    }
    if(response.status === 204){
        return;
    }
    return await response.json();
}