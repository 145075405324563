import {ISystemSettingsService} from "./ISystemSettingsService";
import {handleResponse} from "../../HelperFunctions/handleResponse";
import {SystemSettingsUpdateViewModel} from "../../Pages/Settings/ViewModels/SystemSettingsUpdateViewModel";

export class SystemSettingsService implements ISystemSettingsService {
    baseUrl: string = '/api/systemSettings/';

    public async GetSystemSettingsByUserId(): Promise<SystemSettingsUpdateViewModel> {
       return this.GetRequest("");
    }

    public async UpdateSystemSettings(updateViewModel: SystemSettingsUpdateViewModel, controller: AbortSignal) {
       return this.PutRequest("update", updateViewModel, controller);
    }

    private async GetRequest(path: string, signal?: AbortSignal) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }

    private async PutRequest(path: string, requestObject: any, signal: AbortSignal) {
        let url = this.baseUrl + path;
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: requestObject ? JSON.stringify(requestObject) : "",
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }
}