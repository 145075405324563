import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {OptionType} from "../../../ViewModels/OptionType";
import {ServiceContext} from "../../../Context/ServiceContext";
import {OmniSearchLookupViewModel} from "../../../ViewModels/OmniSearch/OmniSearchLookupViewModel";


export type OmniSearchCategoryOptions = {
    category: string,
    options: OptionType[]
}

export const useOmniSearch = () => {
    const listInnerRef = useRef();
    const initialState = [{category: "", options: []}]
    const {OmniSearchService} = useContext(ServiceContext);
    const [options, setOptions] = useState<OmniSearchCategoryOptions[]>(initialState);
    const isMounted = useRef(true);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [query, setQuery] = useState("");
    const navigate = useNavigate();

    

    const validateViewModelIsInCorrectFormat = (data: OmniSearchLookupViewModel) => {
        if (query === "") {
            setOptions(initialState);
        }
        populateOmniSearchOptions(data);
    };

    const populateOmniSearchOptions = (data: OmniSearchLookupViewModel) => {
        const options = [{category: "Customers", options: [...data.customerLookupViewModel]}, {category: "Plans", options: [...data.planLookupViewModel]}];
        setOptions(options);
    };

    const handleOmniSearchValue = () => {
        const controller = new AbortController();
        OmniSearchService.Search(controller.signal, query.trim())
            .then((data: OmniSearchLookupViewModel) => {
                setIsLoaded(false);
                validateViewModelIsInCorrectFormat(data);
                setIsLoaded(true);
            })
            .catch((err: Error) => {
                console.log(err);
            })
        return () => {
            isMounted.current = false;
            controller.abort();
        }
    }

    const handleClearState = () => {
        setSelectedCategory("");
        setOptions(initialState);
        setQuery("");
    }

    const handleNavigationToSelectedValue = (id: number, category: string, customerId?: number) => {
        if (category === "Plans") {
            navigate(`/customers/${customerId}/plans/${id}`);
        } else {
            navigate(`/${category.toLowerCase()}/${id}`);
        }
        handleClearState();
    }

    useEffect(() => {
        if (query === "") {
            return handleClearState()
        }
        handleOmniSearchValue();
    }, [query])


    return {
        options,
        isLoaded,
        query,
        listInnerRef,
        selectedCategory,
        setSelectedCategory,
        setQuery,
        handleOmniSearchValue,
        handleNavigationToSelectedValue
    };
}