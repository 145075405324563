import {IAPIService} from "../APIService/IAPIService";
import {ILookupService} from "../Lookup/ILookupService";
import {OptionType} from "../../ViewModels/OptionType";

export class RegionService implements ILookupService {
    baseUrl: string = '/api/regions/'
    private readonly _apiService: IAPIService;
    
    constructor(apiService: IAPIService) {
        this._apiService = apiService;
    }

    ListOptions(): Promise<OptionType[]> {
        return this._apiService.Get(this.baseUrl + "list");
    }
}