import {IAPIService} from "./IAPIService";
import {handleResponse} from "../../HelperFunctions/handleResponse";

export class APIService implements IAPIService {
    public async Get(path: string, controller?: AbortSignal): Promise<any> {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: controller
        };

        return fetch(path, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }

    public async Post(path: string, requestBody: any, controller: AbortSignal): Promise<void> {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: controller,
            body: requestBody ? JSON.stringify(requestBody) : ""
        };

        return fetch(path, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }

    public async PostFile(path: string, file: File, signal: AbortSignal){
        const formData = new FormData();
        formData.append('file', file);

        const requestOptions = {
            method: "POST",
            body: formData,
            signal: signal
        };

        return fetch(path, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })
    }

    public async Put(path: string, requestBody: any, controller: AbortSignal): Promise<void> {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: controller,
            body: requestBody ? JSON.stringify(requestBody) : ""
        };

        return fetch(path, requestOptions)
            .then(handleResponse)
            .then((data) => {
                return data;
            })}
}