import {Outlet} from "react-router-dom";
import {UserContext} from "../Context/UserContext";
import {useContext} from "react";
import {VerticalNavbar} from "../GlobalComponents/VerticalNavbar";
import {HeaderNavbar} from "../GlobalComponents/HeaderNavbar";
import {Theme} from "../Themes/types";
import {ThemeContext} from "../Context/ThemeContext";

export const PrivateRoutes = () => {
    const {user} = useContext(UserContext)
    const {userType} = user;
    const {toggleTheme} = useContext(ThemeContext);
    
    
    const selectedTheme = (userType == 1) ? Theme.TrueHouse : Theme.BeLit
    toggleTheme(selectedTheme)
    return (
        <>
            {(() => {

                switch (userType) {
                    case 0:
                        return (
                            <div className="bg-primary min-h-screen">
                                <Outlet />
                            </div>
                        )
                    case 1:
                        return (
                            <div className="bg-primary min-h-screen">
                                <Outlet />
                            </div>
                        )
                    case 2:
                        return (
                            <div className="flex row min-h-screen">
                                <VerticalNavbar />
                                <div className="w-full">
                                    <HeaderNavbar/>
                                    <Outlet />
                                </div>
                            </div>
                        )
                    default:
                        return <></>
                }
            })()}
        </>
    )
}